import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['searchContainer', 'query', 'hiddenIdField', 'resultsContainer', 'resultsFrame', 'selectedContainer', 'selectedValue'];
  static values = { url: String }

  search(event) {
    if (event.target.value.length > 2) {
      this.resultsFrameTarget.src = this.urlValue.replace(/%s/g, encodeURIComponent(event.target.value))
      this.showElement(this.resultsContainerTarget)
    } else {
      this.hideElement(this.resultsContainerTarget)
    }
  }

  select(event) {
    var selectedId = event.params.id
    var selectedValue = event.params.value

    this.hideElement(this.resultsContainerTarget)
    this.hideElement(this.searchContainerTarget)
    this.showElement(this.selectedContainerTarget)
    this.selectedValueTarget.innerText = selectedValue
    this.hiddenIdFieldTarget.value = selectedId
    event.preventDefault()
  }

  clear(event) {
    this.selectedValueTarget.innerText = ""
    this.hiddenIdFieldTarget.value = ""
    this.queryTarget.value = ""
    this.showElement(this.searchContainerTarget)
    this.hideElement(this.selectedContainerTarget)
    this.queryTarget.focus()
    event.preventDefault()
  }

  hideElement(elem) {
    elem.classList.remove("visible")
    elem.classList.add("hidden")
  }

  showElement(elem) {
    elem.classList.remove("hidden")
    elem.classList.add("visible")
  }
}