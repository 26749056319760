import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["roleSelector"]

  connect() {
    this.roleSelectorTarget.addEventListener('change', (e) => {
      const value = e.target.value;
      const url = {
        "Candidate": "/candidate/dashboard",
        "Employer": "/employer/dashboard",
        "Staff": "/staff/dashboard"
      }[value]

      if(url) {
        window.location = url;
      } else {
        console.error("Could not determine redirect!")
      }
    })
  }
}
