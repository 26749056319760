import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['results', 'query'];
  static values = {url: String, action: String, debounceTimer: Number }

  search() {
    this.debounce(this.performSearch.bind(this), 500);
  }

  performSearch() {
    var frame = document.getElementById(`${this.actionValue}`)
    frame.src = this.urlValue.replace(/%s/g, encodeURIComponent(this.queryTarget.value))
  }

  debounce(callback, time) {
    clearTimeout(this.debounceTimerValue);
    this.debounceTimerValue = setTimeout(callback, time);
  }
}