import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const locationHash = window.location.hash;
    if (locationHash && locationHash == '#register') {
      document.getElementById('register').click();
    }
    if (locationHash && (locationHash == '#sign-in' || locationHash == '#signin')) {
      document.getElementById('sign-in').click();
    }
  }
}
