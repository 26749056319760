import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let form = this.element
    this.removeExistingToken(form)
    form.appendChild(this.authenticityToken)
  }

  get authenticityToken() {
    const input = document.createElement("input")

    input.type = "hidden"
    input.name = "authenticity_token"
    input.value = document.querySelector("meta[name='csrf-token']").content

    return input
  }

  removeExistingToken(form) {
    const input = form.querySelector("input[name='authenticity_token']")
    if (input) {
      input.remove()
    }
  }
}