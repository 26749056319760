import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {
  async registerCandidate(event) {
    const response = await this.submitForm(event)
    if (response.ok) {
      dataLayer.push({event: 'Registration', event_category: 'Candidate', event_label: 'Success', send_to: 'G-8Q16ZPY0KY'})
    }
    response.renderTurboStream()
  }

  async createProfile(event) {
    const response = await this.submitForm(event)
    if (response.redirected && !response.response.url.includes('registration_wizard')) {
      window.location.href = response.response.url
    } else {
      response.renderTurboStream()
    }
  }

  async submitForm(event) {
    event.preventDefault()
    const recaptchaToken = await this.scoreUser()
    const formData = new FormData(event.target.form)
    formData.append('candidate_registration_wizard_contact_info_form[recaptcha_token]', recaptchaToken)
    const request = new FetchRequest('post', event.target.form.action, { responseKind: 'turbo-stream', body: formData })
    return await request.perform()
  }

  async scoreUser() {
    return await window.grecaptcha.execute('6LcsCj4pAAAAAKHxx4SWYQXhCvynmswirqah_oNo', {action: 'submit'})
  }
}