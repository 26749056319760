import { Controller } from "@hotwired/stimulus"
import DateTimeFormatter from "../utils/date_time_formatter"
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export default class extends Controller {
  static targets = ['startDateTime', 'output']

  connect() {
    this.updateCountDown()
    this.interval = setInterval(() => { this.updateCountDown() }, 6000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  updateCountDown() {
    const startDate = dayjs(this.startDateTimeTarget.innerText);
    const currentDate = dayjs();
    const duration = dayjs.duration(startDate.diff(currentDate));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const milliseconds = duration.asMilliseconds();

    if (days < -1) {
        this.outputTarget.textContent = "Event has passed";
    } else if (milliseconds < 0) {
        this.outputTarget.textContent = "Event has started";
    } else if (days === 0 && milliseconds > 0) {
        this.outputTarget.innerHTML= `<span class='text-primary-1 font-bold'>Countdown to Kickoff</span><br/>${hours} Hours ${minutes.toString().padStart(2, '0')} Mins Remaining`;
    } else {
        this.outputTarget.textContent = DateTimeFormatter.fullDate(startDate);
    }
  }
}
