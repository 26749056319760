import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {
  static targets = ["loadingMoreContainer", "nextPageUrl"]
  INTERSECTION_THRESHOLD = 0.9

  connect() {
    var observer = new IntersectionObserver(changes => {
      for (const change of changes) {
        if (change.intersectionRatio >= this.INTERSECTION_THRESHOLD) {
          this.loadNextPage();
          return;
        }
      }
    }, { threshold: [0.9] });

    if (this.hasLoadingMoreContainerTarget) {
      observer.observe(this.loadingMoreContainerTarget);
    }
  }

  loadNextPage() {
    const request = new FetchRequest('get', this.nextPageUrlTarget.value, { responseKind: 'turbo-stream' });
    request.perform();
  }
}
