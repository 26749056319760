import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {
  static targets = ['resultsCountUrl', 'resultsValue', 'loadingContainer']

  async count(event) {
    this.resultsValueTarget.innerText = 'Calculating...'
    this.loadingContainerTarget.classList.remove('hidden')
    event.preventDefault()
    const frm = event.target.nodeName == 'FORM' ? event.target : event.target.form
    const formData = new FormData(frm)
    formData.delete("_method")

    const params = new URLSearchParams(formData)
    const url = new URL(this.resultsCountUrlTarget.value, window.location.origin)
    url.search = params.toString()
    const request = new FetchRequest('GET', url.toString(), { responseKind: 'turbo-stream' })
    return await request.perform()
  }
}