import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {
  static targets = ["recipientCountUpdateUrl", "launchModal", "confirmationEntry", "actualCount", "launchContainer", "launchButton", "launchDialogButton", "editor"]
  static values = { sendTestPath: String }

  editorTargetConnected() {
    let config = Object.assign({ target: this.editorTarget }, TinyMCERails.configuration.default )
    tinymce.init(config)
  }

  editorTargetDisconnected() {
    tinymce.remove()
  }

  async count(event) {
    this.disableLaunch() //force save after changes to launch
    event.preventDefault()
    const frm = event.target.nodeName == 'FORM' ? event.target : event.target.form
    const formData = new FormData(frm)
    formData.delete("_method")
    const request = new FetchRequest('post', this.recipientCountUpdateUrlTarget.value, { responseKind: 'turbo-stream', body: formData })
    return await request.perform()
  }

  async sendTest(event) {
    event.preventDefault()
    const frm = event.target.form
    const formData = new FormData(frm)
    formData.delete("_method")
    const request = new FetchRequest('post', this.sendTestPathValue, { responseKind: 'turbo-stream', body: formData })
    return await request.perform()
  }

  disableLaunch() {
    this.launchDialogButtonTarget.disabled = true
    this.launchDialogButtonTarget.innerHTML = "Save changes before launching"
  }

  showLaunch(event) {
    event.preventDefault()
    this.launchModalTarget.classList.remove('hidden')
  }

  closeLaunch(event) {
    event.preventDefault()
    this.launchModalTarget.classList.add('hidden')
  }

  confirmCount(event) {
    event.preventDefault()
    if (this.actualCountTarget.innerText == this.confirmationEntryTarget.value){
      this.launchContainerTarget.classList.remove('text-red-800', 'bg-red-200')
      this.launchContainerTarget.classList.add('text-green-800', 'bg-green-200')
    }else{
      this.launchContainerTarget.classList.remove('text-green-800', 'bg-green-200')
      this.launchContainerTarget.classList.add('text-red-800', 'bg-red-200')
    }
  }

  selectDeliveryOption(event) {
    if (this.actualCountTarget.innerText == this.confirmationEntryTarget.value){
      this.launchButtonTarget.disabled = false
    }

    const container = event.target.labels[0].parentNode
    const deliveryOption = event.target.value

    container.querySelectorAll("label").forEach((el) => {
      el.classList.remove('bg-slate-50')
    })
    event.target.labels[0].classList.add('bg-slate-50')

    container.querySelectorAll("#options").forEach((el) => {
      el.classList.add('hidden')
    })

    event.target.labels[0].querySelector("#options").classList.remove('hidden')
  }



}