import { Controller } from "@hotwired/stimulus"
import DateTimeFormatter from "../utils/date_time_formatter"

export default class extends Controller {
  static targets = ['output']

  connect() {
    this.updateTime();
    this.interval = setInterval(() => { this.updateTime() }, 6000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  updateTime() {
    const currentDate = new Date();
    this.outputTarget.textContent = DateTimeFormatter.dateWithTimeZone(currentDate)
  }
}
