import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  submit(event) {
    if (event.detail.success) {
      const response = event.detail.fetchResponse
      if (!response.redirected) return

      Turbo.visit(response.response.url)
    }
  }
}
