import { Controller } from "@hotwired/stimulus"
import PickerController from "./picker_controller"

export default class SearchPickerController extends PickerController {
  static targets = [...PickerController.targets, 'query', 'hiddenIdField', 'resultsFrame', 'selectedContainer', 'selectedValue'];
  static values = { url: String, minLength: Number, debounceTimer: Number, showSample: Boolean };

  connect() {
    super.connect()
    if (this.hiddenIdFieldTarget.value && this.hiddenIdFieldTarget.dataset.selectedValue) {
      var selectedValue = this.hiddenIdFieldTarget.dataset.selectedValue;
      this.renderValue(selectedValue, this.hiddenIdFieldTarget.value);
    }
    if(this.showSampleValue) {
      this.search()
    }
    this.boundPositionDropdown = this.positionDropdown.bind(this)
  }

  inputHandler(event) {
    this.debounce(this.debouncedInputHandler.bind(this, event), 500)
  }

  search() {
    this.resultsFrameTarget.src = this.urlValue.replace(/%s|%25s/g, encodeURIComponent(this.queryTarget.value))
    this.showElement(this.resultsContainerTarget)
  }

  select(event) {
    var selectedId = event.params.id
    var selectedValue = event.params.value

    this.renderValue(selectedValue, selectedId);
    this.hiddenIdFieldTarget.dispatchEvent(new Event('input'))
    this.dispatch("select", { detail: { id: selectedId, value: selectedValue } })
    this.hiddenIdFieldTarget.dispatchEvent(new Event('change'))
    event.preventDefault()
  }

  renderValue(selectedValue, selectedId) {
    this.hideElement(this.resultsContainerTarget);
    this.hideElement(this.searchContainerTarget);
    this.showElement(this.selectedContainerTarget);
    this.selectedValueTarget.innerText = selectedValue;
    this.hiddenIdFieldTarget.value = selectedId;
  }

  clear(event) {
    this.selectedValueTarget.innerText = ""
    this.hiddenIdFieldTarget.value = ""
    this.hiddenIdFieldTarget.dispatchEvent(new Event('input'))
    this.queryTarget.value = ""
    this.showElement(this.searchContainerTarget)
    this.hideElement(this.selectedContainerTarget)
    this.queryTarget.focus()

    this.dispatch("clear")
    event.preventDefault()
  }
}
