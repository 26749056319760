import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['results'];
  static values = { url: String }

  connect() {
    console.log("multi search controller is connected");
  }

  initialize() {
    console.log("multi-search controller is initialized")
  }

  search(event) {
    var frame = document.getElementById("multisearch_frame")
    var container = document.getElementById("multisearchResults")

    if (event.target.value.length > 2) {
      frame.src = this.urlValue.replace(/%s/g, encodeURIComponent(event.target.value))
      container.classList.remove("hidden")
      container.classList.add("visible")
    } else {
      container.classList.remove("visible")      
      container.classList.add("hidden")
    }
  }

  hideResult() {
    var result_container = document.getElementById("multisearchResults")
    result_container.classList.remove("visible")
    result_container.classList.add("hidden")
  }
}