import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateInput"]
  static values = {defaultDate: String, frameSelector: String}

  connect() {
    this.initDatePicker();
  }

  initDatePicker() {
    window.flatpickr((this.dateInputTarget), {
      defaultDate: this.defaultDateValue,
      disableMobile: "true",
      plugins: [
        new monthSelectPlugin({
          shorthand: true,
          dateFormat: "F Y",
          altFormat: "F Y",
        })
      ],
      onClose: function (selectedDates, dateStr, instance) {
        this.dispatch("monthYearClosed", {});
      }.bind(this),
    });
  }
}