import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => {
      let formEntries = new FormData(this.element).entries()
      let initialFormValue = JSON.stringify(Object.fromEntries(formEntries))
      this.element.dataset.initialFormValue = initialFormValue
    }, 1000);
  }
}
