import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {
  static targets = ['field', 'errors', 'parent'];
  static values = { field: String, validators: String, form: String }
  
  parsedValidators() {
    return JSON.parse(this.validatorsValue)
  }
  
  connect() {

  }
  
  async validate(event) {
    var errors = await this.collectValidationErrors()
    if (errors.length > 0) {
      this.errorsTarget.innerHTML = errors.join(", ")
      this.errorsTarget.classList.remove("hidden")
      this.fieldTarget.classList.add("border-red-500")
    } else {
      this.errorsTarget.classList.add("hidden")
      this.fieldTarget.classList.remove("border-red-500")
      this.errorsTarget.innerHTML = ""
    }    
  }

  async clearValidationErrors(event) {
    var errors = await this.runClientSideValidations()
    if (errors.length == 0) {
      this.errorsTarget.classList.add("hidden")
      this.fieldTarget.classList.remove("border-red-500")
      this.errorsTarget.innerHTML = ""
    }
  }

  async collectValidationErrors() {
    const clientErrors = await this.runClientSideValidations()
    const serverErrors = await this.runServerSideValidations()
    return clientErrors.concat(serverErrors)
  }

  async runServerSideValidations() {
    const errors = []
    for (const validator of this.parsedValidators()) {
      if (validator.name == "server"){
        const error = await this.validateServer(validator)
        if (error) {
          errors.push(error)
        }
      }
    }
    return errors
  }

  async runClientSideValidations() {
    const errors = []
    for (const validator of this.parsedValidators()) {
      if (validator.name == "required"){
        const error = await this.validateRequired(validator)
        if (error) {
          errors.push(error)
        }
      }
      if (validator.name == "format"){
        const error = await this.validateFormat(validator)
        if (error) {
          errors.push(error)
        }
      }
    }

    // because the confirm validation live on the password field instead of the password_confirmation field
    // we need to check if the password_confirmation field is present and if it is, we need to validate it against 
    // the password field if the password field is present and has the confirm validator
    if (this.fieldTarget.name.indexOf("_confirmation") > -1) {
      const passwordField = this.fieldTarget.form.querySelector(`input[name='${this.fieldTarget.name.replace('_confirmation', '')}']`) //this is okay, but needs to be built from the confirmation and use some sort of contains becuase the way rails builds element names

      if (passwordField) {
        const passwordParent = passwordField.parentNode.parentNode.parentNode // would be better if it traveresed up the dom until it found the parent with the data-client-validations-parent-target

        const passwordValidators = JSON.parse(passwordParent.dataset.clientValidationsValidatorsValue)
        if (passwordValidators.some(validator => validator.name == "confirmation")) {
          const error = await this.validateConfirm(passwordField.value, this.fieldTarget.value)
          if (error) {
            errors.push(error)
          }
        }
      }
    }

    return errors
  }

  async validateConfirm(value, confirmationValue) {
    if (value != confirmationValue) {
      return "doesn't match"
    }
  }

  async validateRequired(validator) {
    const value = this.fieldTarget.value
    if (value == "") {
      return "can't be blank"
    }
  }

  async validateFormat(validator) {
    const value = this.fieldTarget.value
    const regex = new RegExp(validator.options.with.replace(/\\A|\\z/g, ""), "g")
    if (!regex.test(value)) {
      return validator.options.message || "is invalid format"
    }
  }

  async validateServer(validator) {
    try {
      const value = this.fieldTarget.value
      const request = new FetchRequest('post', `${window.location.origin}/api/v1/field_validation/`, {
        body: JSON.stringify({field: this.fieldValue, form: this.formValue, inputs: this.cleanedFormState()})
      })

      const response = await request.perform()
      const body = await response.json

      if (body.errors.length > 0) {
        return body.errors
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  }

  formState() {
    const formData = new FormData(this.fieldTarget.form)
    const formState = {}
    formData.forEach((value, key) => {
      if (key.indexOf("[]") > -1) {
        if(formState[key]) {
          formState[key].push(value)
        } else {
          formState[key] = [value]
        }
      } else {
        formState[key] = value
      }
    })
    return formState
  }

  cleanedFormState() {
    const fieldDenylist = ["password_confirmation","middle_name","verify_email","user_languages"]
    const formState = this.formState()

    Object.keys(formState).forEach(key => {
      if (fieldDenylist.some(element => key.includes(element))) {
        delete formState[key]
      }
    })

    return formState
  }
}