import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {

  async save(event) {
    let results = await this.saveSubForms(event)
    if (results.every(result => result)) {
      if (event.target.form && !event.target.form.dataset.noSubmit) {
        const formData = new FormData(event.target.form)
        const request = new FetchRequest('post', event.target.form.action, { responseKind: 'turbo-stream', body: formData })
        const response = await request.perform()
        response.renderTurboStream()
      }
    }
  }

  async saveContinue(event) {
    let results = await this.saveSubForms(event)
    if (results.every(result => result)) {
      if (event.target.form && !event.target.form.dataset.noSubmit) {
        const formData = new FormData(event.target.form)
        formData.append('commit', 'Save & Continue')
        const request = new FetchRequest('post', event.target.form.action, { responseKind: 'turbo-stream', body: formData })
        const response = await request.perform()
        if (response.redirected) {
          window.location.href = response.response.url
        } else {
          response.renderTurboStream()
        }
      } else {
        setTimeout(() => {
          if(event.target.closest('turbo-frame')) {
            window.dispatchEvent(new CustomEvent('set-tab', { detail: event.target.closest('turbo-frame').querySelector("input[name='next_tab']").value }))
          } else {
            // Saving an expanded sub form may swap out the turbo frame.
            // Make the user press save again.
            return
          }
        }, 300);
      }
    }
  }

  async saveSubForms(event) {
    event.preventDefault()
    const container = document.getElementById(event.params.id)
    const openForms = container.getElementsByTagName('form')
    let results = []
    for(const openForm of openForms) {
      const request = new FetchRequest('post', openForm.action, { responseKind: 'turbo-stream', body: new FormData(openForm) })
      const response = await request.perform()
      response.renderTurboStream()
      results.push(response.statusCode === 200)
      if (response.statusCode !== 200) {
        window.dispatchEvent(new CustomEvent('set-tab', { detail: event.target.form.current_tab.value }))
      }
    }
    return results
  }
}