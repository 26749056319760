import { Controller } from "@hotwired/stimulus"
import DatePickerController from "./date_picker_controller"

export default class extends DatePickerController {
  static values = {defaultDate: String, frameSelector: String, showAllTime: Boolean, allTimeStartDate: String}

  connect() {
    this.predefinedRanges = {
      Today: [new Date(), new Date()],
      'Last 7 Days': [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date(),
      ],
      'Month to Date': [
          new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1
          ),
          new Date(),
      ],
      'Year to Date': [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(),
      ],
      'Trailing 12 Months': [
          new Date(new Date().setMonth(new Date().getMonth() - 12)),
          new Date(),
      ],
      'Previous Month': [
          new Date(
              new Date().getFullYear(),
              new Date().getMonth() - 1,
              1
          ),
          new Date(new Date().setDate(0)),
      ],
      'Previous Year': [
          new Date(new Date().getFullYear() - 1, 0, 1),
          new Date(new Date().getFullYear() - 1, 11, 31),
      ],
      'All Time': [
          new Date(this.allTimeStartDateValue),
          new Date(),
      ],
      Custom: [null, null],
    }
    if (!this.showAllTimeValue) {
      delete this.predefinedRanges['All Time'];
    }
    this.initDatePicker();
  }

  initDatePicker() {
    super.initDatePicker(this.dateInputTarget, {
      plugins: [
        this.predefinedDateRangePlugin({
          predefinedRanges: this.predefinedRanges
        })
      ],
      onClose: function (selectedDates, dateStr, instance) {
          let { startDate, endDate } = this.getSelectedDates(selectedDates, instance);
          if (
              selectedDates[0] !== instance._initStartDate ||
              selectedDates[1] !== instance._initEndDate
          ) {
              this.reloadStats(startDate, endDate);
          }
          this.setDisplayDate(selectedDates, instance);
      }.bind(this),
    });
  }

  predefinedDateRangePlugin(config) {
    return function (instance) {
      function compareDates(date1, date2) {
        return date1.setHours(0, 0, 0, 0) - date2.setHours(0, 0, 0, 0);
      }

      function isPredefinedRange(selectedDates, predefinedRanges, key) {
        const predefinedRange = predefinedRanges[key];
        return (
            compareDates(selectedDates[0], predefinedRange[0]) === 0 &&
            compareDates(selectedDates[1], predefinedRange[1]) === 0
        );
      }

      return {
        onReady: function (selectedDates) {
          const fpBodyWrapper = document.createElement('div');
          fpBodyWrapper.className = 'relative';

          const fpMonths = instance.monthsDropdownContainer.parentNode.parentNode.parentNode;
          fpMonths.classList.add('border-b-2');
          const fpInnerContainer = instance.innerContainer
          fpBodyWrapper.appendChild(fpMonths);
          fpBodyWrapper.appendChild(fpInnerContainer);
          instance.calendarContainer.prepend(fpBodyWrapper);

          const mainContainer = document.createElement('div');
          mainContainer.className = 'flex items-center border-b-2 pb-1 predefined-date-picker-container';

          const labelContainer = document.createElement('div');
          labelContainer.className = 'w-1/3';
          const label = document.createElement('label');
          label.className = 'block mt-1 text-sm font-medium text-gray-700';
          label.innerText = 'Date Range: ';
          labelContainer.appendChild(label);
          mainContainer.appendChild(labelContainer);

          const selectContainer = document.createElement('div');
          selectContainer.className = 'w-2/3';
          const select = document.createElement('select');
          select.className = 'mt-1 block w-full pl-3 pr-3 py-1 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md';
          select.onchange = function () {
            const selectedDateRange = this.value;
            const selectedDates = config.predefinedRanges[selectedDateRange];
            if (selectedDates[0] && selectedDates[1]) {
              instance.setDate(selectedDates, true);
              instance.close();
            }
          };
          let foundPredefinedRange = false;
          for (const key in config.predefinedRanges) {
            const option = document.createElement('option');
            option.value = key;
            option.innerText = key;
            if (!foundPredefinedRange && key !== 'Custom' && isPredefinedRange(selectedDates, config.predefinedRanges, key)) {
              option.selected = true;
              foundPredefinedRange = true;
            } else if (!foundPredefinedRange && key === 'Custom') {
              option.selected = true;
            }
            select.appendChild(option);
          }
          selectContainer.appendChild(select);
          mainContainer.appendChild(selectContainer);

          instance.calendarContainer.prepend(mainContainer);
          instance.calendarContainer.classList.add('flatpick-with-predefined-range-picker');
        }
      }
    }
  }

  reloadStats(startDate, endDate) {
    if (this.frameSelectorValue) {
      const frame = document.querySelector(this.frameSelectorValue),
            url = new URL(frame.src);
      url.searchParams.set('start_date', startDate);
      url.searchParams.set('end_date', endDate);
      frame.src = url.href;
      frame.reload();
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set('start_date', startDate);
      url.searchParams.set('end_date', endDate);
      Turbo.visit(url.href);
    }
  }
}