import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['searchContainer', 'queryInput', 'resultsContainer', 'resultsFrame',
                    'selectedContainer', 'selectedTemplate', 'selectedList',
                    'selectedOptionTemplate', 'selectedOptions']
  static values = { minLength: Number, debounceTimer: Number, selected: Array, country: String, region: String }

  connect() {
    this.selected = this.selectedValue
    this.selected.forEach((selected) => {
      this.renderSelectedItem(selected.id, selected.label)
    })
  }

  inputHandler(event) {
    this.debounce(this.debouncedInputHandler.bind(this, event), 500)
  }

  debouncedInputHandler(event) {
    if (event.target.value.length > this.minLengthValue) {
      this.search()
    } else {
      this.hideElement(this.resultsContainerTarget)
    }
  }

  blurHandler(event) {
    if (!this.resultsContainerTarget.contains(event.relatedTarget) && !this.searchContainerTarget.contains(event.relatedTarget)) {
      this.hideElement(this.resultsContainerTarget)
    }
    event.preventDefault()
  }

  search() {
    const service = new google.maps.places.AutocompleteService();
    var options = {
      input: this.queryInputTarget.value, types: ["(regions)"],
      componentRestrictions: { country: this.countryValue },
      region: this.regionValue
    }
    service.getPlacePredictions(options, this.displaySuggestions.bind(this));
    this.showElement(this.resultsContainerTarget)
  }

  displaySuggestions(predictions, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      this.resultsContainerTarget.innerHTML = ""
      var ul = this.resultsContainerTarget.appendChild(document.createElement("ul"))
      ul.classList = 'relative divide-y divide-gray-200'
      this.buildSuggestionHTML(predictions[0])
      predictions.forEach((prediction) => {
        var li = ul.appendChild(document.createElement("li"))
        li.innerHTML = this.buildSuggestionHTML(prediction)
      })
    } else if (status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
      this.resultsContainerTarget.innerHTML = ""
      var ul = this.resultsContainerTarget.appendChild(document.createElement("ul"))
      ul.classList = 'relative divide-y divide-gray-200'
      var li = ul.appendChild(document.createElement("li"))
      li.innerHTML = "<li class='text-gray-700 hover:bg-gray-100 cursor-pointer w-full px-2 py-3'>No results found</li>"
    }
  }

  buildSuggestionHTML(prediction) {
    var text = prediction.description
    var matched_substring = prediction.matched_substrings[0]
    var matched_text = text.substring(matched_substring.offset, matched_substring.offset + matched_substring.length)
    var display_text = text.replace(matched_text, "<strong>" + matched_text + "</strong>")
    return `<li role="option" tabindex="-1"><a href="" class="flex px-3 py-2 relative hover:cursor-pointer hover:bg-gray-100 focus-within:ring-2 focus-within:ring-inset focus-within:ring-rm-dark-blue" data-action="click->location-picker#select" data-location-picker-id-param="${text}" data-location-picker-value-param="${text}">${display_text}</a></li>`
  }

  select(event) {
    var selectedId = event.params.id
    var selectedValue = event.params.value

    this.hideElement(this.resultsContainerTarget)
    this.queryInputTarget.value = ""
    this.renderSelectedItem(selectedId, selectedValue)
    this.selectedOptionsTarget.dispatchEvent(new Event('change'))
    this.queryInputTarget.focus()
    event.preventDefault()
  }

  clear(event) {
    var id = event.params.id

    this.removeSelectedItem(id)
    this.selectedOptionsTarget.dispatchEvent(new Event('change'))
    this.queryInputTarget.focus()
    event.preventDefault()
  }

  renderSelectedItem(id, label) {
    let item = this.selectedTemplateTarget.content.cloneNode(true);
    item.querySelector(".chip-box").setAttribute("data-id", id)
    item.querySelector('.chip-label').innerText = label;
    item.querySelector('.remove-button').dataset.locationPickerIdParam = id;
    this.selectedListTarget.appendChild(item);
    let option = this.selectedOptionTemplateTarget.content.cloneNode(true);
    option.querySelector('option').value = id;
    option.querySelector('option').innerText = label;
    this.selectedOptionsTarget.appendChild(option);
  }

  removeSelectedItem(id) {
    this.selectedOptionsTarget.querySelector(`option[value="${id}"]`).remove()
    this.selectedListTarget.querySelector(`.chip-box[data-id="${id}"]`).remove()
  }

  hideElement(elem) {
    elem.classList.remove("visible")
    elem.classList.add("hidden")
  }

  showElement(elem) {
    elem.classList.remove("hidden")
    elem.classList.add("visible")
  }

  debounce(callback, time) {
    clearTimeout(this.debounceTimerValue);
    this.debounceTimerValue = setTimeout(callback, time);
  }
}