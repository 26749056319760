// Entry point for the build script in your package.json
import "chartkick/chart.js"
require("@hotwired/turbo-rails")
import * as ActiveStorage from "@rails/activestorage"
import "./channels"
import "./controllers"
import ahoy from "ahoy.js"
//import "./stylesheets/application"


//Rails.start()
ActiveStorage.start()

// Load Ahoy to window
window.ahoy = ahoy