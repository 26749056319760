import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['queryInput', 'resultsContainer'];
  static values = { debounceTimer: Number, selectUrl: String, usOnly: Boolean };

  inputHandler() {
    this.debounce(this.search.bind(this), 500);
  }

  search() {
    if (this.queryInputTarget.value.length > 0) {
      const service = new google.maps.places.AutocompleteService();
      var options = { input: this.queryInputTarget.value, types: ["(regions)"]}
      if (this.usOnlyValue) {
        options.componentRestrictions = {country: 'us'}
        options.region = 'us'
      }
      service.getPlacePredictions(options, this.displaySuggestions.bind(this));
    }
  }

  displaySuggestions(predictions, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      this.resultsContainerTarget.innerHTML = ""
      var ul = this.resultsContainerTarget.appendChild(document.createElement("ul"))
      ul.classList = 'list-none flex flex-col gap-2 w-full'
      this.buildSuggestionHTML(predictions[0])
      predictions.forEach((prediction) => {
        var li = ul.appendChild(document.createElement("li"))
        li.innerHTML = this.buildSuggestionHTML(prediction)
      })
    } else if (status == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
      this.resultsContainerTarget.innerHTML = ""
      var ul = this.resultsContainerTarget.appendChild(document.createElement("ul"))
      ul.classList = 'list-none flex flex-col gap-2 w-full'
      var li = ul.appendChild(document.createElement("li"))
      li.innerHTML = "<li class='text-gray-700 hover:bg-gray-100 cursor-pointer w-full px-2 py-3'>No results found</li>"
    }
  }

  buildSuggestionHTML(prediction) {
    var text = prediction.description
    var matched_substring = prediction.matched_substrings[0]
    var matched_text = text.substring(matched_substring.offset, matched_substring.offset + matched_substring.length)
    var display_text = text.replace(matched_text, "<strong>" + matched_text + "</strong>")
    if(this.selectUrlValue !== '') {
      var href = this.selectUrlValue.replace(/%s/g, encodeURIComponent(text))
      return `<li class="w-full flex"><a class="text-gray-700 hover:bg-gray-100 cursor-pointer w-full px-2 py-3" href="${href}">${display_text}</a></li>`
    } else {
      return `<li class="w-full flex"><a class="text-gray-700 hover:bg-gray-100 cursor-pointer w-full px-2 py-3" data-action="click->google-place-predictions#select" data-text="${text}">${display_text}</a></li>` 
    }
  }

  select(event) {
    if (event.target.tagName === 'A') {
      this.queryInputTarget.value = event.target.dataset.text
    } else {
      this.queryInputTarget.value = event.target.parentElement.dataset.text
    }
    this.queryInputTarget.form.submit();
  }

  debounce(callback, time) {
    clearTimeout(this.debounceTimerValue);
    this.debounceTimerValue = setTimeout(callback, time);
  }
}