import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export default {
  dateWithTimeZone: function(date) {
    return dayjs(date).format('dddd, MMMM D, YYYY | hh:mm A z')
  },

  fullDate: function(date) {
    return dayjs(date).format('dddd, MMMM D, YYYY')
  }
}