import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {

  cancelNewEducation(event) {
    event.preventDefault()
    event.target.closest('.new_candidate_education_form').remove();
  }

  deleteEducation(event) {
    event.preventDefault()
    if (confirm("Are you sure you want to delete this education?")) {
      const request = new FetchRequest('DELETE', event.target, { responseKind: 'turbo-stream' });
      request.perform();
    }
  }
}