import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ['container', 'actual']

  submit(event) {
    if (this.actualTarget.value == "true") {
      this.actualTarget.value = "false"
    } else {
      this.actualTarget.value = "true"
    }
    this.containerTarget.requestSubmit()
  }
}
