import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  inputHandler(custom_event) {
    const selectedValue = custom_event.detail.value;
    let url = new URL(window.location.href);
    const fields = url.searchParams.getAll('filters[][field]');
    const terms = url.searchParams.getAll('filters[][term]');
    const labels = url.searchParams.getAll('filters[][label]');
    url = this.clearFilterParams(url);

    for(let [index, field] of fields.entries()) {
      if(field !== 'transition_installation') {
        url.searchParams.append('filters[][field]', field);
        url.searchParams.append('filters[][term]', terms[index]);
        url.searchParams.append('filters[][label]', labels[index]);
      }
    }
    url.searchParams.append('filters[][field]', 'transition_installation');
    url.searchParams.append('filters[][term]', selectedValue);
    url.searchParams.append('filters[][label]', selectedValue);
    Turbo.visit(url.href);
  }

  clearFilterParams(url) {
    url.searchParams.delete('filters[][field]');
    url.searchParams.delete('filters[][term]');
    url.searchParams.delete('filters[][label]');
    return url;
  }
}