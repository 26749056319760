import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {phone: String}

  dial() {
    document.getElementById('dialpad-frame').contentWindow.postMessage({
      'api': 'opencti_dialpad',
      'version': '1.0',
      'method': 'initiate_call',
      'payload': {
        'enable_current_tab': false,
        'phone_number': this.phoneValue,
        'custom_data': 'any string'
      }
    }, 'https://dialpad.com');
  }
}