import DateRangePickerController from "./date_range_picker_controller"
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

export default class extends DateRangePickerController {
  static targets = ["output", "options", "optionTemplate", "rangeStartDate", "rangeEndDate"]

  connect() {
    this.generateDateRanges();
    this.updateOutput();
    this.renderOptions();
  }

  generateDateRanges() {
    const today = dayjs();
    const thisWeekStart = today.startOf("week");
    const thisWeekEnd = today.endOf("week");
    const thisMonthStart = today.startOf("month");
    const thisMonthEnd = today.endOf("month");
    const thisQuarterStart = today.startOf("quarter");
    const thisQuarterEnd = today.endOf("quarter");
    const lastWeekStart = today.subtract(1, "week").startOf("week");
    const lastWeekEnd = today.subtract(1, "week").endOf("week");
    const lastMonthStart = today.subtract(1, "month").startOf("month");
    const lastMonthEnd = today.subtract(1, "month").endOf("month");
    const lastQuarterStart = today.subtract(1, "quarter").startOf("quarter");
    const lastQuarterEnd = today.subtract(1, "quarter").endOf("quarter");

    this.constructor.dateRanges = [
      {label: 'Entire Period', startDate: null, endDate: null},
      {label: 'Today', startDate: today, endDate: today},
      {label: 'This Week', startDate: thisWeekStart, endDate: thisWeekEnd},
      {label: 'This Month', startDate: thisMonthStart, endDate: thisMonthEnd},
      {label: 'This Quarter', startDate: thisQuarterStart, endDate: thisQuarterEnd},
      {label: 'Last Week', startDate: lastWeekStart, endDate: lastWeekEnd},
      {label: 'Last Month', startDate: lastMonthStart, endDate: lastMonthEnd},
      {label: 'Last Quarter', startDate: lastQuarterStart, endDate: lastQuarterEnd},
    ];
  }

  updateOutput() {
    const outputTarget = this.outputTarget;
    const startDate = this.data.get("startDate") ? dayjs(this.data.get("startDate")) : null;
    const endDate = this.data.get("endDate") ? dayjs(this.data.get("endDate")) : null;
    const selected = this.constructor.dateRanges.find((dateRange) => {
      return (dateRange.startDate && dateRange.startDate.isSame(startDate, 'day') &&
        dateRange.endDate && dateRange.endDate.isSame(endDate, 'day') ) ||
        startDate === null && endDate === null && dateRange.label === 'Entire Period';
    });
    outputTarget.innerHTML = `<p> ${this.formatDateRangeLabel(selected, startDate, endDate)}</p>`;
  }

  renderOptions() {
    const dateRanges = this.constructor.dateRanges;

    dateRanges.reverse().forEach((dateRange) => {
      const option = this.optionTemplateTarget.content.cloneNode(true);
      option.childNodes[1].innerHTML = dateRange.label;
      option.childNodes[1].addEventListener("click", () => this.handleOptionClick(dateRange.label));

      this.optionsTarget.prepend(option);
    });
  }

  handleOptionClick(label) {
    const dateRange = this.constructor.dateRanges.find((dateRange) => dateRange.label === label);
    const startDate = dateRange.startDate ? this.formatDate(dateRange.startDate) : null;
    const endDate = dateRange.endDate ? this.formatDate(dateRange.endDate) : null;
    this.selectDateRange(startDate, endDate);
  }

  formatDateRangeLabel(selected, startDate, endDate) {
    if (selected) {
      return `<p>${selected.label}</p>`;
    } else {
      return super.formatDateRangeLabel(startDate, endDate);
    }
  }
}