import { Controller } from "@hotwired/stimulus"
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

export default class extends Controller {
  static targets = ["output", "options", "optionTemplate", "rangeStartDate", "rangeEndDate"]

  connect() {
    this.updateOutput();
  }

  updateOutput() {
    const outputTarget = this.outputTarget;
    const startDate = this.data.get("startDate") ? dayjs(this.data.get("startDate")) : null;
    const endDate = this.data.get("endDate") ? dayjs(this.data.get("endDate")) : null;
    outputTarget.innerHTML = `<p> ${this.formatDateRangeLabel(startDate, endDate)}</p>`;
  }

  handleRangeSubmit(event) {
    event.preventDefault();
    const startDate = this.rangeStartDateTarget.value;
    const endDate = this.rangeEndDateTarget.value;
    this.selectDateRange(startDate, endDate);
  }

  selectDateRange(startDate, endDate) {
    const turboFrameTarget = this.data.get("turboFrameTarget")
    this.setDateRange(startDate, endDate)
    this.updateOutput()

    if (turboFrameTarget) {
      // Update TurboFrame content
      const turboFrame = document.querySelector(`#${turboFrameTarget}`)
      const newUrl = this.buildUrl(turboFrame.src, startDate, endDate)
      turboFrame.src = newUrl.href
    } else {
      // Reload the entire page
      const newUrl = this.buildUrl(window.location.href, startDate, endDate);
      window.location.href = newUrl.toString();
    }
  }

  setDateRange(startDate, endDate) {
    startDate ? this.data.set("startDate", startDate) : this.data.delete("startDate");
    endDate ? this.data.set("endDate", endDate) : this.data.delete("endDate");
  }

  buildUrl(path, startDate, endDate) {
    const url = new URL(path);
    startDate ? url.searchParams.set("start_date", startDate) : url.searchParams.delete("start_date");
    endDate ? url.searchParams.set("end_date", endDate) : url.searchParams.delete("end_date");
    return url;
  }

  formatDateRangeLabel(startDate, endDate) {
    if (startDate && !endDate) {
      return `<p>From ${this.formatDate(startDate)} to Present</p>`;
    } else if (!startDate && endDate) {
      return `<p>Until ${this.formatDate(endDate)}</p>`;
    } else if (startDate && endDate) {
      return `<p>${this.formatDate(startDate)} to ${this.formatDate(endDate)}</p>`;
    } else {
      return `<p>Select Range</p>`;
    }
  }

  formatDate(date) {
    return dayjs(date).format("YYYY-MM-DD");
  }
}