import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:click', this.handleLinkClick);
    document.addEventListener('turbo:submit-start', this.handleSubmitStart);
    document.addEventListener('turbo:frame-render', this.handleFrameRender);
    document.addEventListener("turbo:frame-load", this.handleAutofocus);
    this.insureInputCentersOnFocusForMobile();
  }

  handleLinkClick = (e) => {
    if(e.target.classList.contains('shows-loading')) {
      let loadingIcon = e.target.getElementsByClassName('loading-icon')[0]; 
      console.log(loadingIcon)
      if (loadingIcon) {
        loadingIcon.classList.add('is-loading');
        loadingIcon.classList.remove('hidden');
      }
    }
  }

  openTableRowLink = (e) => {
    if(e.target.closest('td').dataset.preventClick) {
      return
    }
    e.target.parentNode.getElementsByTagName('a')[0]?.click();
  }

  handleFrameRender = (e) => {
    Array.from(document.getElementsByClassName('is-loading')).forEach(element => {
      element.classList.add('hidden')
      element.classList.remove('is-loading');
    });
  }

  handleSubmitStart = (e) => {
    let form = e.target;
    let loadingIcon = form.getElementsByClassName('loading-icon')[0];
    if (loadingIcon) {
      loadingIcon.classList.add('is-loading');
      loadingIcon.classList.remove('hidden');
    }
  }

  handleAutofocus = (e) => {
    const frame = e.target;
    const input = frame.querySelector('input[autofocus]');
    if (input) {
      input.focus()
    }
  }

  insureInputCentersOnFocusForMobile = () => {
    document.addEventListener('touchstart', function handler(e) {
      let target = e.target;
      if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
        setTimeout(function() {
          target.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 300);
      }
    });
  }
}
