import { Controller } from "@hotwired/stimulus"
// import dayjs from 'dayjs'
// import quarterOfYear from 'dayjs/plugin/quarterOfYear'
// dayjs.extend(quarterOfYear)

export default class extends Controller {
  static targets = ["output", "options", "optionTemplate", "rangeStartDate", "rangeEndDate", "actual"]

  connect() {
    this.updateOutput();
  }

  updateOutput() {
    const outputTarget = this.outputTarget;
    const actualTarget = this.actualTarget;
    const startDate = this.data.get("startDate")? this.data.get("startDate") : null // ? Date.parse(this.data.get("startDate")) : null;
    const endDate = this.data.get("endDate") ? this.data.get("endDate") : null // Date.parse(this.data.get("endDate")) : null;
    outputTarget.innerHTML = `<p> ${this.formatDateRangeLabel(startDate, endDate)}</p>`;
    actualTarget.value = this.formatDateRangeLabel(startDate, endDate);
  }

  handleRangeSubmit(event) {
    event.preventDefault();
    const startDate = this.rangeStartDateTarget.value;
    const endDate = this.rangeEndDateTarget.value;
    this.selectDateRange(startDate, endDate);
  }

  selectDateRange(startDate, endDate) {    
    this.setDateRange(startDate, endDate)
    this.updateOutput()

  }

  setDateRange(startDate, endDate) {
    startDate ? this.data.set("startDate", startDate) : this.data.delete("startDate");
    endDate ? this.data.set("endDate", endDate) : this.data.delete("endDate");
  }

  formatDateRangeLabel(startDate, endDate) {
    if (startDate && !endDate) {
      return `after ${this.formatDate(startDate)}`;
    } else if (!startDate && endDate) {
      return `before ${this.formatDate(endDate)}`;
    } else if (startDate && endDate) {
      return `${this.formatDate(startDate)} to ${this.formatDate(endDate)}`;
    } else {
      return `Select Range`;
    }
  }

  formatDate(date) {
    return date
    // return dayjs(date).format("YYYY-MM-DD");
  }
}