import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateInput", "tmpDisplay"]
  static values = {defaultDate: String, flatpickr: String}

  connect() {
    this.initDatePicker();
    console.log("date picker connected", this)
  }

  disconnect() {
    this.findAndRemoveElement('.flatpickr-calendar');
    this.findAndRemoveElement(".date-range-picker[readonly='readonly']");
  }

  initDatePicker(input = null, options = {}) {
    options = Object.assign(JSON.parse(this.flatpickrValue || "{}"), options)
    options = Object.assign({
      mode: 'range',
      altInput: true,
      allowInvalidPreload: true,
      altFormat: 'M d, Y',
      dateFormat: 'm/d/Y',
      plugins: [],
      onReady: function (selectedDates, dateStr, instance) {
          instance._initStartDate = selectedDates[0];
          instance._initEndDate = selectedDates[1];
          if(selectedDates.length > 0) {
            this.setDisplayDate(selectedDates, instance);
          } else if(this.defaultDateValue) {
            this.setDisplayText(instance, this.defaultDateValue)
          } else {
            this.setDisplayText(instance, 'Not Selected')
          }
      }.bind(this),
      onClose: function (selectedDates, dateStr, instance) {
        this.setDisplayDate(selectedDates, instance);
      }.bind(this),
    }, options)

    window.flatpickr(input || this.dateInputTarget, options)
  }

  findAndRemoveElement(selector) {
    const elem = this.element.querySelector(selector);
    elem ? elem.parentNode.removeChild(elem) : null;
  }

  getSelectedDates(selectedDates, instance) {
    let startDate = selectedDates[0];
    let endDate = selectedDates[1];
    if (!endDate) {
        endDate = new Date();
    }
    if (startDate > endDate) {
        let tempDate = startDate;
        startDate = endDate;
        endDate = tempDate;
    }
    startDate = instance.formatDate(startDate, instance.config.dateFormat);
    endDate = instance.formatDate(endDate, instance.config.dateFormat);
    return { startDate, endDate };
  }

  setDisplayDate(selectedDates, instance) {
    const formattedStartDate = selectedDates[0] ? instance.formatDate(selectedDates[0], instance.config.altFormat) : null;
    const formattedEndDate = selectedDates[1] ? instance.formatDate(selectedDates[1], instance.config.altFormat) : null;

    if (formattedStartDate && formattedEndDate) {
      const displayDate = `${formattedStartDate} to ${formattedEndDate}`;
      this.setDisplayText(instance, displayDate)
    }
  }

  setDisplayText(instance, text) {
    this.tmpDisplayTarget.innerText = instance.altInput.value = text;
    this.tmpDisplayTarget.hidden = true;
  }
}