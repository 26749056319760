import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'query', 'form', 'clearButton'];
  static values = { url: String, minLength: Number, debounceTimer: Number }

  inputHandler() {
    this.debounce(this.search.bind(this), 500);
    if (this.queryTarget.value.length > 0) {
      this.showElement(this.clearButtonTarget)
    } else {
      this.hideElement(this.clearButtonTarget)
    }
  }

  search() {
    this.formTarget.requestSubmit()
  }

  clear() {
    this.queryTarget.value = ""
    this.queryTarget.focus()
    this.formTarget.requestSubmit()
    this.hideElement(this.clearButtonTarget)
  }

  hideElement(elem) {
    elem.classList.remove("visible")
    elem.classList.add("hidden")
  }

  showElement(elem) {
    elem.classList.remove("hidden")
    elem.classList.add("visible")
  }

  debounce(callback, time) {
    clearTimeout(this.debounceTimerValue);
    this.debounceTimerValue = setTimeout(callback, time);
  }
}