import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'video',
    'shortCode',
    'successContainer',
    'errorContainer',
    'results'
  ];

  connect() {
    // dynamic import to load esm at runtime, and resolving it, 
    // will log the default export of the module.
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      const video = this.element.querySelector('video');
      import("@zxing/library/esm").then(reader => {
        this.codeReader = new reader.BrowserQRCodeReader();
        this.notFound = reader.NotFoundException;
        this.checksum =  reader.ChecksumException;
        this.format =  reader.FormatException;
        this.codeReader.timeBetweenDecodingAttempts = 500
        this.decodeContinuously(this.codeReader, undefined, video);
      })
    }
  }

  disconnect() {
    this.codeReader.reset();
  }

  submit(e) {
    this.saveCandidate(this.shortCodeTarget.value)
  }

  dismiss(e) {
    const { id } = e.target;
    if (id === 'dismissError') {
      this.errorContainerTarget.classList.add('hidden');
    }
    else {
      this.successContainerTarget.classList.add('hidden');
    }
    // clear src attr because it will not rescan as src is already loaded into the DOM
    this.resultsTarget.src = '';
  }

  decodeContinuously(codeReader, selectedDeviceId, video) {
    codeReader.decodeFromInputVideoDeviceContinuously(selectedDeviceId, video, (result, err) => {
      if (result) {
        console.log('Found QR code!', result)
        const arr = result.text.split("/")
        if (!this.resultsTarget.hasAttribute("src") || (this.resultsTarget.hasAttribute("src")  && this.resultsTarget.src == '')) {
          this.saveCandidate(arr[arr.length - 1])
        } 
      }
  
      if (err) {
        if (err instanceof this.notFound) {
          console.log('No QR code found.')
        }
  
        if (err instanceof this.checksum) {
          console.log('A code was found, but it\'s read value was not valid.')
        }
  
        if (err instanceof this.format) {
          console.log('A code was found, but it was in a invalid format.')
        }
      }
    })
  }
  
  saveCandidate(shortCode) {
    this.resultsTarget.src = `/share/save_candidate/${shortCode}`
    this.shortCodeTarget.value = ''
  }
}