import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {

  cancelNewPosition(event) {
    event.preventDefault()
    event.target.closest('.new_candidate_position_form').remove();
  }

  deletePosition(event) {
    event.preventDefault()
    if (confirm("Are you sure you want to delete this position?")) {
      const request = new FetchRequest('DELETE', event.target, { responseKind: 'turbo-stream' });
      request.perform();
    }
  }
}