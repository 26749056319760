import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'


export default class extends Controller {

  sendGETRequest(event) {
    event.preventDefault()
    const request = new FetchRequest('GET', event.target, { responseKind: 'turbo-stream' });
    request.perform();
  }
}