import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['results'];
  static values = {url: String}

  connect() {}

  search(event) {
    this.resultsTarget.src = this.urlValue.replace(/%s/g, encodeURIComponent(event.target.value))
  }
}