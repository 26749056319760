import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["table", "record", "bulkActionBar", "selectedRecordCount"]
  selectedRecords = new Set()

  connect() {

  }

  selectRecord(event) {
    const tr = event.target.closest('tr')
    const id = tr.dataset.id
    if(!id) {
      throw "You must set 'tr[data-id]' property to use multiple select!"
    }

    if(this.selectedRecords.has(id)) {
      this.selectedRecords.delete(id)
      tr.classList.remove('bg-primary-3')
      tr.querySelector('input[type="checkbox"]:first-of-type').checked = false
    } else {
      this.selectedRecords.add(id)
      tr.classList.add('bg-primary-3')
      tr.querySelector('input[type="checkbox"]:first-of-type').checked = true
    }

    if(this.selectedRecords.size) {
      this.bulkActionBarTarget.classList.remove('hidden')
      this.selectedRecordCountTarget.innerText = `${this.selectedRecords.size} Records Selected`
    } else {
      this.bulkActionBarTarget.classList.add('hidden')
    }
    event.stopPropagation()
  }

  bulkAction(event) {
    if(!event.target) return

    const confirmMessage = event.target.dataset.confirm ?
      event.target.dataset.confirm.replace("%d", this.selectedRecords.size) :
      `Are you sure you want to ${event.target.dataset.name.toLowerCase()} ${this.selectedRecords.size} records?`

    if (confirm(confirmMessage)) {
      if(event.target.dataset.turboFrame) {
        if (event.target.dataset.method.toUpperCase() === 'GET') {
          this.getWithTurboFrame(event)
        }
      } else {
        const form = document.createElement('form')
        form.method = 'POST'
        form.action = event.target.dataset.url
        form.classList.add('hidden')
        form.innerHTML = Array.from(this.selectedRecords).map(id => `<input type="hidden" multiple="multiple" name="selected_ids[]" value="${id}">`)
        form.innerHTML += `<input type="hidden" name="authenticity_token" value=${document.querySelector('meta[name=csrf-token]').content}>`
        event.target.parentElement.appendChild(form)
        form.requestSubmit()
      }
    }
  }

  openTableRowLink(e) {
    if(e.target.closest('td').dataset.preventClick) {
      return
    }
    e.target.closest('td').getElementsByTagName('a')[0]?.click();
  }

  async getWithTurboFrame(event) {
    const url = new URL(`${window.location.origin}${event.target.dataset.url}`)
    this.selectedRecords.forEach(id => url.searchParams.append('selected_ids[]', id))
    document.getElementById(event.target.dataset.turboFrame).src = url
  }
}
